<template>
    <loader v-if="isLoading"/>
    <div class="column-start h-100" v-else>
        <div class="row-between">
            <template v-if="isAnalyticLogRoute">
                <div
                    class="lg-12 row-baseline pointer mb-8 f-15-grey"
                    @click="$router.push({ name: 'QRAnalytics' })"
                >
                    <arrow-shewron
                        :transform="180"
                        width="14"
                        height="14"
                        class="i-green mr-2"
                    />
                    QR Analytics
                </div>
                <div class="column-start lg-6 sm-12">
                    <h1 class="f-28-black f-black">{{ curentMetric }}</h1>
                </div>
            </template>
            <div v-else class="column-start lg-6 sm-12">
                <h1 class="f-28-black f-black">QR Analytics</h1>
                <p class="f-18-grey f-medium mt-4">You can see number of views and clicks on QR.</p>
            </div>
            <div :class="[ mScreen ? 'mt-8 row-center' : 'lg-2 s-align-8']">
                <vue-select
                    v-if="qrLists.length"
                    ref="select"
                    :options="qrLists"
                    :label-by="'name'"
                    close-on-select
                    v-model="selectedQR"
                    searchable
                    :clear-on-select="true"
                    placeholder="Select QR"
                >
                    <template #label="{ selected }">
                        <template v-if="selected">
                            <div>
                                {{ selected.name }}
                            </div>
                        </template>
                        <template v-else>Select QR</template>
                    </template>
                    <template #dropdown-item="{ option }">
                        <div>
                            {{ option.name }}
                        </div>
                    </template>
                </vue-select>
            </div>
            <div :class="[ mScreen ? 'mt-8 row-center' : 's-align-9']">
                <tabs-toggler
                    :tabs="tabs"
                    :selectedTab="analyticsPeriod"
                    @selectedPeriod="selectPeriod"
                />
            </div>
        </div>
        <div class="row-start mt-20" v-if="!isAnalyticLogRoute">
            <div
                v-for="(item, i) in manufacturerAnalyticsData"
                :key="i"
                :class="[
                            'mb-40 sm-12',
                            !mScreen && i + 1 !== 4 && i + 1 !== 5 ? 'pr-40' : '',
                            i + 1 <= 4 ? 'lg-3' : 'lg-12'
                        ]"
            >
                <advanced-analytics-card
                    :data="item"
                    :analyticsPeriod="analyticsPeriod"
                    @click="toMetric(item)"
                    class="pointer"
                />
            </div>
        </div>
        <div class="w-100 pb-40 mt-20" v-else>
            <router-view
                :businessSubuserCardId="selectedQR?.id"
                :selectedPeriod="selectedPeriod"
            />
        </div>
    </div>
</template>

<script>
import {ADVANCED_ANALYTICS_METRICS, DATE_FILTERS_OPTIONS} from "../../constants";
import TabsToggler from "../../components/Analytics/TabsToggler";
import {mapActions, mapGetters} from "vuex";
import {USER_ROLES} from '@/constants';
import NotificationsService from "../../NotificationsService";
import AdvancedAnalyticsCard from "../../components/Analytics/AdvancedAnalyticsCard";
import ArrowShewron from "../../icons/ArrowShewron";

export default {
    name: "QRAnalytics",
    components: {
        TabsToggler,
        AdvancedAnalyticsCard,
        ArrowShewron
    },
    data() {
        return {
            USER_ROLES,
            analyticsPeriod: 'all time',
            selectedQR: null,
            selectedPeriod: null,
            tabs: [
                DATE_FILTERS_OPTIONS.DAY,
                DATE_FILTERS_OPTIONS.WEEK,
                DATE_FILTERS_OPTIONS.MONTH,
                DATE_FILTERS_OPTIONS.CUSTOM,
            ],
            manufacturerAnalyticsData: [
                {
                    type: ADVANCED_ANALYTICS_METRICS.MANUFACTURER.TITLE,
                    metric: ADVANCED_ANALYTICS_METRICS.MANUFACTURER.SLAG,
                    counter: 0
                },
                {
                    type: ADVANCED_ANALYTICS_METRICS.MANUFACTURER_BTN.TITLE,
                    metric: ADVANCED_ANALYTICS_METRICS.MANUFACTURER_BTN.SLAG,
                    counter: 0
                },
            ],
        }
    },
    watch: {
        selectedQR(val, old) {
            if (val?.id && val?.id !== old?.id) {
                let params = {
                    manufacturer_item_id: val?.id,
                    period: this.analyticsPeriod
                }
                if (this.selectedPeriod) {
                    params = {...params, ...this.selectedPeriod};
                }
                this.getAnalytics(params).then(res => {
                    if (res) {
                        this.parseAdvancedAnalytics(res);
                    }
                });
            }
        }
    },
    computed: {
        ...mapGetters({
            userRole: "user/getUserRole",
            userId: "user/getUserId",
            manufacturerItemsData: 'manufacturer/manufacturerItems',
            manufacturerAllItems: "manufacturer/manufacturerAllItems",
            isLoading: "manufacturer/isLoading"
        }),
        curentMetric() {
            if (this.$route?.params?.metric) {
                switch (this.$route.params.metric) {
                    case ADVANCED_ANALYTICS_METRICS.TAPS.SLAG:
                        return ADVANCED_ANALYTICS_METRICS.TAPS.TITLE;
                    case ADVANCED_ANALYTICS_METRICS.CONTACTS.SLAG:
                        return ADVANCED_ANALYTICS_METRICS.CONTACTS.TITLE;
                    case ADVANCED_ANALYTICS_METRICS.DOWNLOAD.SLAG:
                        return ADVANCED_ANALYTICS_METRICS.DOWNLOAD.TITLE;
                    case ADVANCED_ANALYTICS_METRICS.DEVICE.SLAG:
                        return ADVANCED_ANALYTICS_METRICS.DEVICE.TITLE;
                    case ADVANCED_ANALYTICS_METRICS.LINKS.SLAG:
                        return ADVANCED_ANALYTICS_METRICS.LINKS.TITLE;
                    case ADVANCED_ANALYTICS_METRICS.MANUFACTURER_BTN.SLAG:
                        return ADVANCED_ANALYTICS_METRICS.MANUFACTURER_BTN.TITLE
                    case ADVANCED_ANALYTICS_METRICS.MANUFACTURER.SLAG:
                        return ADVANCED_ANALYTICS_METRICS.MANUFACTURER.TITLE
                }
            }
        },
        isAnalyticLogRoute() {
            return this.$route.name === 'QRAnalyticMetric';
        },
        isRoleQRAdmin() {
            return this.userRole === USER_ROLES.QR_ADMIN
        },
        qrLists() {
            const arr = this.isRoleQRAdmin ? this.manufacturerItemsData : this.manufacturerAllItems;
            const lists = arr?.map(el => ({id: el.id, name: el.serial_number}));
            this.selectedQR = lists?.[0] ?? null;
            return lists ?? []
        }
    },
    methods: {
        ...mapActions({
            getAnalytics: 'qrUsers/getAnalyticsQR',
            getManufacturerItemsData: "manufacturer/getManufacturerItems",
            getManufacturerAllItems: "manufacturer/getManufacturerAllItems"
        }),
        parseAdvancedAnalytics(data) {
            this.manufacturerAnalyticsData.forEach(item => {
                switch (item.type) {
                    case ADVANCED_ANALYTICS_METRICS.MANUFACTURER.TITLE:
                        item.counter = data.manufacturer_item_views;
                        break;
                    case ADVANCED_ANALYTICS_METRICS.MANUFACTURER_BTN.TITLE:
                        item.counter = data.manufacturer_item_button_clicks;
                        break;

                }
            })
        },
        selectPeriod(val) {
            let {period, params} = val;
            this.analyticsPeriod = period;
            this.selectedPeriod = params;
            if (!this.selectedQR?.id) {
                NotificationsService.errorNotification('Select QR');
                return;
            }
            const paramsData = {...params, manufacturer_item_id: this.selectedQR?.id};
            this.getAnalytics(paramsData).then(res => {
                if (res) {
                    this.parseAdvancedAnalytics(res);
                }
            });
        },
        toMetric(val) {
            this.$router.push({
                name: 'QRAnalyticMetric',
                params: {
                    metric: val.metric
                }
            });
        },
    },
    created() {
        if (this.isRoleQRAdmin) {
            this.getManufacturerItemsData(this.userId);
        } else {
            this.getManufacturerAllItems();
        }
    }
}
</script>

<style scoped>

</style>
